.article-icon-text {
  max-width: 900px;
  margin: 0 auto; }
  .article-icon-text h1, .article-icon-text h2 {
    color: #E2263C;
    text-transform: uppercase;
    font-size: 26px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 50px; }
  .article-icon-text .inner {
    display: flex; }
  .article-icon-text .icon {
    width: 140px; }
  .article-icon-text .content {
    flex: 1; }
  .article-icon-text.left .inner {
    flex-direction: row; }
  .article-icon-text.left .icon {
    margin-right: 100px; }
  .article-icon-text.right .inner {
    flex-direction: row-reverse; }
  .article-icon-text.right .icon {
    margin-left: 100px; }
  @media only screen and (max-width: 800px) {
    .article-icon-text .inner {
      display: block; }
    .article-icon-text.left .icon, .article-icon-text.right .icon {
      margin: 0 auto 50px auto; } }
