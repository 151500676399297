.article-headlineTextText {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px; }
  @media only screen and (max-width: 800px) {
    .article-headlineTextText {
      display: block; } }

.article-headlineTextText .headline {
  grid-area: 1 / 1 / -1 / 2;
  font-size: 30px;
  line-height: 35px;
  color: #E2263C;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-transform: uppercase; }
  @media only screen and (max-width: 800px) {
    .article-headlineTextText .headline h2 {
      margin-bottom: 30px; } }

.article-headlineTextText .copy {
  grid-area: 1 / 2 / -1 / 4;
  columns: 2 auto;
  column-gap: 50px; }
  @media only screen and (max-width: 1100px) {
    .article-headlineTextText .copy {
      columns: initial; } }
  @media only screen and (max-width: 800px) {
    .article-headlineTextText .copy {
      columns: initial; } }
