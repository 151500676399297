.article-table + .article-table .table {
  border-top: none; }

.article-table:last-of-type {
  margin-bottom: 100px; }

.article-table {
  max-width: 1000px;
  margin: 0 auto 100px auto; }
  .article-table .table {
    font-weight: 100; }
    .article-table .table .thead {
      display: flex;
      margin-bottom: 30px; }
    .article-table .table .th {
      font-weight: 400;
      color: #E2263C;
      letter-spacing: 1px;
      font-size: 18px;
      text-transform: uppercase;
      flex: 1;
      text-align: right;
      padding: 5px; }
      .article-table .table .th:first-child {
        text-align: left;
        margin-left: 0;
        flex: 3; }
    .article-table .table .tr {
      display: flex; }
    .article-table .table .td {
      margin-left: 15px;
      border-bottom: 1px solid #E2263C;
      padding: 6px 0 5px;
      font-size: 16px;
      flex: 1;
      text-align: right; }
      .article-table .table .td:first-child {
        text-align: left;
        color: #E2263C;
        margin-left: 0;
        flex: 3; }
    @media only screen and (max-width: 800px) {
      .article-table .table {
        width: 900px;
        padding: 50px; } }
  @media (max-width: 1200px) {
    .article-table {
      margin: 0 100px 100px 100px; } }
  @media only screen and (max-width: 800px) {
    .article-table {
      margin: 0 0 50px 0;
      overflow: auto; } }
