.overview-tile.triple-left .overview-piece:nth-child(2) h2, .overview-tile.triple-left .overview-piece:nth-child(3) h2, .overview-tile.triple-right .overview-piece:nth-child(2) h2, .overview-tile.triple-right .overview-piece:nth-child(3) h2 {
  font-size: 32px;
  line-height: 35px; }

.overview-tile.triple-left .overview-piece:nth-child(2) button, .overview-tile.triple-left .overview-piece:nth-child(3) button, .overview-tile.triple-right .overview-piece:nth-child(2) button, .overview-tile.triple-right .overview-piece:nth-child(3) button {
  margin: 17px 0 24px; }

.overview-tile {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 620px;
  grid-gap: 4px 4px;
  margin-bottom: 4px; }
  .overview-tile.fullwidth .overview-piece {
    grid-area: 1 / 1 / -1 / -1; }
  .overview-tile.double-left .overview-piece:nth-child(1) {
    grid-area: 1 / 1 / -1 / 9; }
  .overview-tile.double-left .overview-piece:nth-child(2) {
    grid-area: 1 / 9 / -1 / 13; }
  .overview-tile.double-both .overview-piece:nth-child(1) {
    grid-area: 1 / 1 / -1 / 7; }
  .overview-tile.double-both .overview-piece:nth-child(2) {
    grid-area: 1 / 7 / -1 / 13; }
  .overview-tile.double-right .overview-piece:nth-child(1) {
    grid-area: 1 / 1 / -1 / 5; }
  .overview-tile.double-right .overview-piece:nth-child(2) {
    grid-area: 1 / 5 / -1 / 13; }
  .overview-tile.triple-left .overview-piece:nth-child(1) {
    grid-area: 1 / 1 / 3 / 8; }
  .overview-tile.triple-left .overview-piece:nth-child(2) {
    grid-area: 1 / 8 / 2 / 13; }
  .overview-tile.triple-left .overview-piece:nth-child(3) {
    grid-area: 2 / 8 / 3 / 13; }
  .overview-tile.double-equal .overview-piece:nth-child(1) {
    grid-area: 1 / 1 / -1 / 7; }
  .overview-tile.double-equal .overview-piece:nth-child(2) {
    grid-area: 1 / 7 / -1 / 13; }
  .overview-tile.triple-right .overview-piece:nth-child(1) {
    grid-area: 1 / 6 / 3 / 13; }
  .overview-tile.triple-right .overview-piece:nth-child(2) {
    grid-area: 1 / 1 / 2 / 6; }
  .overview-tile.triple-right .overview-piece:nth-child(3) {
    grid-area: 2 / 1 / 3 / 6; }
  .overview-tile:last-child {
    margin-bottom: 0; }
  @media only screen and (max-width: 800px) {
    .overview-tile {
      display: block;
      grid-area: initial;
      height: initial;
      margin: initial; }
      .overview-tile:last-child .overview-piece:last-child {
        margin-bottom: 0; } }

.ie .overview-tile {
  display: block;
  height: auto;
  margin: 0; }

.overview-piece {
  background-color: #f9d3d7;
  color: #fff;
  text-decoration: none;
  background-size: cover;
  background-position: center;
  position: relative; }
  .overview-piece.icon {
    background-color: #E2263C; }
    .overview-piece.icon .image-module {
      background: none; }
    .overview-piece.icon .inner {
      background: none; }
    .overview-piece.icon button {
      border: 2px solid white; }
  .overview-piece .icon-img {
    height: 75px;
    margin-bottom: 30px; }
  .overview-piece .image {
    height: 100%; }
  .overview-piece .inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px; }
    @media only screen and (max-width: 800px) {
      .overview-piece .inner {
        padding: 25px 50px; } }
  .overview-piece h2 {
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    font-weight: 100;
    max-width: 500px; }
    @media only screen and (max-width: 800px) {
      .overview-piece h2 {
        font-size: 36px; } }
  .overview-piece .text {
    margin-top: 20px;
    line-height: 1.5em;
    font-weight: 100;
    text-align: center;
    max-width: 500px; }
  .overview-piece button {
    font-weight: 100;
    background-color: #E2263C;
    color: white;
    padding: 20px 40px;
    font-size: 16px;
    font-weight: 100;
    outline: 0;
    border: 0;
    appearance: none;
    -webkit-appearance: none;
    text-transform: uppercase;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    margin: 40px 0;
    letter-spacing: 1px; }
    .overview-piece button:hover {
      cursor: pointer;
      background: white;
      color: #E2263C; }
    @media only screen and (max-width: 800px) {
      .overview-piece button {
        margin: 20px 0;
        font-size: 14px; } }
  .overview-piece .overview-subline {
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    line-height: 1.25em; }
  @media only screen and (max-width: 800px) {
    .overview-piece {
      display: block;
      grid-area: initial;
      height: 400px;
      margin-bottom: 4px; } }
  .overview-piece.no-curtain .inner {
    background-color: transparent; }
  .overview-piece.text-red {
    color: #E2263C; }

.ie .overview-piece {
  display: block;
  grid-area: initial;
  height: 400px;
  margin-bottom: 4px; }
