.footer {
  font-size: 11px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 50px;
  background: #E2263C;
  color: #fff; }
  @media only screen and (max-width: 800px) {
    .footer {
      display: block; } }

.footer-navi {
  display: flex; }
  .footer-navi .footer-navi-col {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 25px; }
    @media only screen and (max-width: 800px) {
      .footer-navi .footer-navi-col {
        display: flex;
        flex-direction: column; } }
  .footer-navi a {
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    margin-bottom: 10px;
    display: block; }
    .footer-navi a:last-child {
      margin-bottom: 0; }

.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.footer-other {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px; }
  .footer-other a {
    margin-left: 10px; }
    @media only screen and (max-width: 800px) {
      .footer-other a {
        margin: 0 10px 0 0; } }
    .footer-other a img {
      height: 48px; }
  @media only screen and (max-width: 800px) {
    .footer-other {
      justify-content: flex-start;
      margin: 30px 0 0 0; } }

.footer-social {
  text-transform: uppercase; }
  @media only screen and (max-width: 800px) {
    .footer-social {
      margin-top: 30px; } }
  .footer-social a {
    margin-left: 10px; }
    @media only screen and (max-width: 800px) {
      .footer-social a {
        margin: 0 10px 0 0; } }
    .footer-social a img {
      height: 16px; }
