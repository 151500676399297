.article.padded {
  margin-top: 100px; }

section {
  padding: 0 100px;
  margin: 100px auto 100px;
  max-width: 1480px;
  font-weight: 100; }
  @media only screen and (max-width: 800px) {
    section {
      margin: 0 auto 60px;
      padding: 0 30px; } }
  section p {
    font-size: 18px;
    font-weight: 100;
    line-height: 25px;
    margin-bottom: 15px; }
    @media only screen and (max-width: 800px) {
      section p {
        font-size: 16px;
        line-height: 1.5em; } }
  section ul {
    margin: 15px 0 30px; }
    section ul li {
      list-style-type: square;
      font-size: 18px;
      font-weight: 100;
      line-height: 25px;
      margin-bottom: 5px;
      padding-left: 5px;
      margin-left: 20px; }
  section a {
    color: #E2263C; }
  section .picture .image {
    background-color: #666;
    background-size: cover;
    width: 100%;
    height: 300px; }
  section .caption {
    font-size: 12px;
    line-height: 16px;
    margin-top: 10px; }
