.social-area {
  display: flex;
  border: 1px solid #E2263C;
  border-width: 1px 0;
  height: 100px; }
  @media only screen and (max-width: 800px) {
    .social-area {
      display: block;
      height: initial; } }

.social-area-field {
  flex: 1;
  border-left: 1px solid #E2263C; }
  .social-area-field:first-child {
    border-left: none; }
  .social-area-field svg {
    margin-right: 20px;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
  .social-area-field a {
    font-size: 18px;
    color: #E2263C;
    text-decoration: none;
    font-weight: 100; }
  @media only screen and (max-width: 800px) {
    .social-area-field {
      border-width: 0;
      border-top: 1px solid #E2263C; }
      .social-area-field:first-child {
        border-top: none; } }

.SocialMediaShareButton {
  display: block;
  height: 100%;
  width: 100%; }

.social-field-inner, .social-field-inner a {
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-size: 18px;
  color: #E2263C;
  text-decoration: none;
  font-weight: 100;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
  .social-field-inner:hover svg, .social-field-inner a:hover svg {
    margin-right: 40px; }
