.article-event {
  display: flex;
  margin-bottom: 100px;
  border: 1px solid #E2263C;
  border-width: 1px 0; }
  @media only screen and (max-width: 800px) {
    .article-event {
      display: block;
      margin-bottom: 60px; } }
  .article-event .info {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 50px; }
    @media only screen and (max-width: 800px) {
      .article-event .info {
        padding: 30px 30px 0 30px; } }
    .article-event .info h1 {
      font-size: 30px;
      line-height: 35px;
      color: #E2263C;
      text-transform: uppercase;
      margin-bottom: 40px;
      text-align: center; }
    .article-event .info p {
      font-size: 18px;
      line-height: 25px;
      font-weight: 100;
      max-width: 600px;
      margin: 0 auto; }
  .article-event .dates {
    border-left: 1px solid #E2263C;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    padding: 50px;
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    font-weight: 100; }
    @media only screen and (max-width: 800px) {
      .article-event .dates {
        padding: 30px;
        font-size: 14px; } }
    .article-event .dates .date {
      display: flex;
      justify-content: center;
      margin-bottom: 20px; }
      .article-event .dates .date .date-data {
        flex: 1;
        color: #E2263C;
        margin-right: 15px;
        text-align: right; }
      .article-event .dates .date .time-data {
        flex: 1;
        text-align: left; }
    .article-event .dates .date-info {
      color: #E2263C; }
