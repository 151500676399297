.article-audio {
  margin: 0 auto;
  max-width: 840px; }
  .article-audio h3 {
    font-size: 30px;
    line-height: 35px;
    font-weight: 100;
    text-align: center;
    margin-bottom: 50px;
    text-transform: uppercase; }
  .article-audio .audio-controls {
    width: 500px;
    margin: 0 auto;
    display: flex; }
    @media only screen and (max-width: 800px) {
      .article-audio .audio-controls {
        width: 100%; } }
    .article-audio .audio-controls .play, .article-audio .audio-controls .pause {
      border: 1px solid #E2263C;
      height: 50px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
      .article-audio .audio-controls .play polygon, .article-audio .audio-controls .pause polygon {
        transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
      .article-audio .audio-controls .play:hover, .article-audio .audio-controls .pause:hover {
        transform: scale(0.97);
        transform-origin: center; }
    .article-audio .audio-controls .play.playing {
      background: #E2263C; }
      .article-audio .audio-controls .play.playing polygon {
        fill: white; }
    .article-audio .audio-controls .play.paused {
      background: white; }
      .article-audio .audio-controls .play.paused polygon {
        fill: #E2263C; }
    .article-audio .audio-controls .pause.playing {
      background: white; }
      .article-audio .audio-controls .pause.playing polygon {
        fill: #E2263C; }
    .article-audio .audio-controls .pause.paused {
      background: #E2263C; }
      .article-audio .audio-controls .pause.paused polygon {
        fill: white; }
  .article-audio .audio-progress {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
    .article-audio .audio-progress .current-time, .article-audio .audio-progress .total-time {
      color: #E2263C;
      font-size: 12px;
      margin: 0 20px;
      width: 60px;
      text-align: center; }
    .article-audio .audio-progress .bar {
      height: 20px;
      border: 1px solid #E2263C;
      width: 500px;
      position: relative;
      cursor: pointer; }
      .article-audio .audio-progress .bar .bar-inner {
        height: 100%;
        background: #E2263C; }
    .article-audio .audio-progress.shown {
      height: 20px;
      opacity: 1; }
    .article-audio .audio-progress.hidden {
      height: 0;
      opacity: 0; }
