.language-switch {
  position: relative;
  border: 1px solid #fff;
  border-width: 0 0 0 1px; }

.language-display {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px; }
  @media only screen and (max-width: 800px) {
    .language-display {
      padding: 15px;
      width: 90px; }
      .language-display .text {
        display: none; } }

.language-dropdown {
  position: absolute;
  width: 100%;
  box-sizing: content-box;
  right: -1px;
  top: calc(100% - 1px);
  background: #fff;
  border: 1px solid #E2263C;
  color: #E2263C; }
  .language-dropdown.invisible {
    display: none; }
  .language-dropdown .language {
    padding: 15px;
    border-bottom: 1px solid #E2263C;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
    .language-dropdown .language:last-child {
      border-bottom: none; }
    .language-dropdown .language:hover {
      cursor: pointer;
      background: #E2263C;
      color: #fff; }
