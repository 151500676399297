.article-imageTwoOne {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px; }
  @media only screen and (max-width: 800px) {
    .article-imageTwoOne {
      display: block; } }

.article-imageTwoOne .bigImage, .article-imageTwoOne .smallImage {
  min-height: 630px;
  background-size: cover; }
  @media only screen and (max-width: 800px) {
    .article-imageTwoOne .bigImage, .article-imageTwoOne .smallImage {
      min-height: 350px; } }

.article-imageTwoOne .bigImage {
  grid-area: 1 / 1 / -1 / 3; }
  @media only screen and (max-width: 800px) {
    .article-imageTwoOne .bigImage {
      margin-bottom: 30px; } }

.article-imageTwoOne .smallImage {
  grid-area: 1 / 3 / -1 / 4; }
