.article-textFullWidth {
  max-width: 890px;
  margin: 0 auto; }
  .article-textFullWidth.center {
    text-align: center; }
  .article-textFullWidth img {
    margin: 0 auto; }
  .article-textFullWidth h1, .article-textFullWidth h2, .article-textFullWidth h3, .article-textFullWidth h4 {
    line-height: 1.25em;
    font-weight: 100;
    text-align: center;
    color: #E2263C;
    max-width: 800px;
    margin: 0 auto 30px auto; }
  .article-textFullWidth h1 {
    font-size: 42px; }
  .article-textFullWidth h2 {
    font-size: 36px; }
  .article-textFullWidth h3 {
    text-align: left;
    margin-left: 0;
    font-size: 24px; }
  .article-textFullWidth h4 {
    text-align: left;
    margin-left: 0;
    font-size: 18px; }
