.article-quote {
  color: #E2263C; }
  .article-quote .quote {
    font-size: 30px;
    line-height: 35px;
    font-style: italic;
    max-width: 890px;
    margin: 0 auto;
    text-align: center; }
  .article-quote .author {
    font-size: 18px;
    line-height: 25px;
    margin: 50px auto 0;
    text-align: center; }
