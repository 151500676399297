.navigation {
  position: fixed;
  top: 75px;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 60; }
  @media only screen and (max-width: 800px) {
    .navigation {
      display: block;
      padding-top: 59px; } }
  .navigation .link {
    display: flex;
    padding: 15px 30px;
    text-decoration: none;
    align-items: center; }
    .navigation .link .title {
      flex: 1;
      color: #E2263C;
      text-transform: uppercase;
      font-weight: 100;
      font-size: 24px;
      margin-right: 50px; }
    .navigation .link .icon {
      height: 100%;
      width: 70px;
      margin-right: 50px; }
      .navigation .link .icon > div {
        background-size: contain;
        height: 60px;
        background-position: center center;
        background-repeat: no-repeat; }
      .navigation .link .icon .red {
        display: block; }
      .navigation .link .icon .white {
        display: none; }
    .navigation .link svg {
      height: 36px; }
      .navigation .link svg polyline {
        fill: none;
        stroke: #E2263C;
        stroke-width: 2px; }
    .navigation .link:hover {
      background: #E2263C; }
      .navigation .link:hover .title {
        color: white; }
      .navigation .link:hover .icon .red {
        display: none; }
      .navigation .link:hover .icon .white {
        display: block; }
      .navigation .link:hover polyline {
        stroke: white; }
    @media only screen and (max-width: 800px) {
      .navigation .link {
        padding: 10px; }
        .navigation .link .icon {
          width: 40px;
          height: 40px;
          margin-right: 20px; }
          .navigation .link .icon > div {
            height: 40px; }
        .navigation .link .title {
          font-size: 16px;
          margin-right: 20px; }
        .navigation .link svg {
          height: 24px; } }
