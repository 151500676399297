.article-textImageImage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px; }
  @media only screen and (max-width: 800px) {
    .article-textImageImage {
      display: block; } }

.article-textImageImage .copy {
  font-size: 18px;
  line-height: 25px; }
  .article-textImageImage .copy.center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }

@media only screen and (max-width: 800px) {
  .article-textImageImage .col {
    margin-bottom: 30px; } }
