.article-gallery-fifty {
  display: flex;
  flex-wrap: wrap; }
  .article-gallery-fifty .item {
    width: calc(50% - 25px);
    margin: 0 50px 50px 0; }
    .article-gallery-fifty .item .picture {
      width: 100%;
      height: 350px;
      margin-bottom: 25px; }
    .article-gallery-fifty .item .description {
      text-align: center; }
    .article-gallery-fifty .item:nth-child(2n) {
      margin-right: 0; }
  @media screen and (max-width: 1000px) {
    .article-gallery-fifty {
      display: block; }
      .article-gallery-fifty .item {
        width: 100%; }
        .article-gallery-fifty .item .picture {
          height: 40vw; } }
