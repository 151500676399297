.article-imageTwoTextOne {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px; }
  @media only screen and (max-width: 800px) {
    .article-imageTwoTextOne {
      display: block; } }

.article-imageTwoTextOne .image {
  background: #666;
  background-size: cover;
  min-height: 630px;
  grid-area: 1 / 1 / -1 / 3; }
  @media only screen and (max-width: 800px) {
    .article-imageTwoTextOne .image {
      min-height: 350px; } }

.article-imageTwoTextOne .copy {
  min-height: 630px;
  text-align: center;
  grid-area: 1 / 3 / -1 / 4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  line-height: 35px;
  font-weight: 100; }
  .article-imageTwoTextOne .copy h3 {
    color: #E2263C;
    font-size: 26px; }
  @media only screen and (max-width: 800px) {
    .article-imageTwoTextOne .copy {
      min-height: initial;
      margin-top: 60px; } }
