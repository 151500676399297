.article-navi-area {
  display: flex;
  border-width: 1px 0;
  height: 100px; }
  @media only screen and (max-width: 800px) {
    .article-navi-area {
      display: block;
      height: initial; } }

.article-navi-field {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  border-left: 1px solid #E2263C;
  cursor: pointer; }
  .article-navi-field:first-child {
    border-left: none; }
  .article-navi-field svg {
    transition: margin 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
    @media only screen and (max-width: 800px) {
      .article-navi-field svg {
        height: 14px;
        width: 14px; } }
  .article-navi-field.back svg {
    margin-right: 20px; }
  .article-navi-field.back:hover svg {
    margin-right: 40px; }
  .article-navi-field.next svg {
    margin-left: 20px; }
  .article-navi-field.next:hover svg {
    margin-left: 40px; }
  .article-navi-field a {
    font-size: 30px;
    text-transform: uppercase;
    color: #E2263C;
    text-decoration: none;
    font-weight: 100; }
    @media only screen and (max-width: 800px) {
      .article-navi-field a {
        font-size: 20px; } }
  @media only screen and (max-width: 800px) {
    .article-navi-field:first-of-type {
      border-bottom: 1px solid #E2263C; } }
