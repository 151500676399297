.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 60;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  color: #fff;
  border-bottom: 1px solid #fff;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1); }
  .header.filled {
    background-color: #E2263C;
    color: #fff; }
  .header.hide {
    transform: translateY(-100%); }
  .header.default-red:not(.filled) {
    border-bottom: 1px solid #E2263C;
    color: #E2263C; }
    .header.default-red:not(.filled) .logo path,
    .header.default-red:not(.filled) .logo polygon {
      fill: #E2263C; }
    .header.default-red:not(.filled) .language-switch,
    .header.default-red:not(.filled) .burger {
      border-left: 1px solid #E2263C; }
      .header.default-red:not(.filled) .language-switch polygon,
      .header.default-red:not(.filled) .language-switch path,
      .header.default-red:not(.filled) .language-switch rect,
      .header.default-red:not(.filled) .burger polygon,
      .header.default-red:not(.filled) .burger path,
      .header.default-red:not(.filled) .burger rect {
        fill: #E2263C; }
      .header.default-red:not(.filled) .language-switch:hover,
      .header.default-red:not(.filled) .burger:hover {
        background-color: #E2263C;
        color: #fff; }
        .header.default-red:not(.filled) .language-switch:hover polygon,
        .header.default-red:not(.filled) .language-switch:hover path,
        .header.default-red:not(.filled) .language-switch:hover rect,
        .header.default-red:not(.filled) .burger:hover polygon,
        .header.default-red:not(.filled) .burger:hover path,
        .header.default-red:not(.filled) .burger:hover rect {
          fill: #fff; }
    .header.default-red:not(.filled) .burger {
      border-left: 1px solid #E2263C; }
      .header.default-red:not(.filled) .burger:hover path {
        stroke: #fff; }

.logo {
  padding: 25px;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 800px) {
    .logo {
      width: 130px;
      padding: 15px; } }

.header-interactive {
  display: flex;
  justify-content: space-between;
  align-items: stretch; }
  .header-interactive > div {
    display: flex;
    align-items: center; }
    .header-interactive > div .text {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px; }
    .header-interactive > div .icon {
      margin-right: 10px; }
    .header-interactive > div .chevron {
      margin-left: 10px; }

.language-switch,
.burger {
  padding: 25px;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
  .language-switch:hover,
  .burger:hover {
    background-color: #fff;
    color: #E2263C; }
    .language-switch:hover polygon,
    .language-switch:hover path,
    .language-switch:hover rect,
    .burger:hover polygon,
    .burger:hover path,
    .burger:hover rect {
      fill: #E2263C; }
  @media only screen and (max-width: 800px) {
    .language-switch,
    .burger {
      padding: 15px; } }

.burger {
  border-left: 1px solid #fff; }
  .burger:hover path {
    stroke: #E2263C; }

.language-switch {
  padding: 0; }
