.title-intro {
  padding: 0 50px;
  text-align: center;
  max-width: 1000px;
  font-size: 18px;
  line-height: 25px;
  font-weight: 100;
  margin: 100px auto; }
  .title-intro h1 {
    font-size: 38px;
    line-height: 38px;
    margin-bottom: 30px;
    color: #E2263C; }
    @media only screen and (max-width: 800px) {
      .title-intro h1 {
        font-size: 48px;
        line-height: 1em; } }
  .title-intro .segoe {
    margin-bottom: 0; }
    .title-intro .segoe a {
      color: #E2263C;
      text-decoration: none; }
      .title-intro .segoe a:hover {
        border-bottom: 1px solid #E2263C; }
  .title-intro p {
    margin-bottom: 30px; }
