.article-textTwoOne {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px; }
  @media only screen and (max-width: 800px) {
    .article-textTwoOne {
      display: block; } }

.article-textTwoOne .big {
  grid-area: 1 / 1 / -1 / 3; }

.article-textTwoOne .small {
  grid-area: 1 / 3 / -1 / 4; }
