.article-textTextImage {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px; }
  @media only screen and (max-width: 800px) {
    .article-textTextImage {
      display: block; } }

.article-textTextImage .copy {
  grid-area: 1 / 1 / -1 / 3;
  columns: 2 auto;
  column-gap: 50px; }
  .article-textTextImage .copy h2 {
    margin: 40px 0;
    font-size: 30px;
    line-height: 35px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 100; }
  @media only screen and (max-width: 1100px) {
    .article-textTextImage .copy {
      columns: initial; } }
  @media only screen and (max-width: 800px) {
    .article-textTextImage .copy {
      columns: initial;
      margin-bottom: 30px; } }

.article-textTextImage .image {
  grid-area: 1 / 3 / -1 / 4;
  background-size: cover; }
  @media only screen and (max-width: 800px) {
    .article-textTextImage .image {
      height: 350px; } }
