.article-slideshow .legend {
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: center; }

.article-slideshow .close {
  display: none; }

.article-slideshow .slider .item {
  width: 840px; }
  .article-slideshow .slider .item .picture {
    height: 630px;
    margin: 30px;
    background-color: #666;
    background-size: cover;
    background-position: center; }
    @media only screen and (max-width: 800px) {
      .article-slideshow .slider .item .picture {
        height: 300px;
        margin: 0 15px 15px 15px; } }
  .article-slideshow .slider .item .caption {
    text-align: center;
    font-size: 18px;
    line-height: 25px; }
    @media only screen and (max-width: 800px) {
      .article-slideshow .slider .item .caption {
        font-size: 12px;
        line-height: 18px;
        margin: 15px 15px 0 15px; } }

.article-slideshow.full-width .slider .item .picture {
  margin: 0; }

.article-slideshow.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #E2263C;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100; }
  .article-slideshow.fullscreen .slider {
    width: 90vw; }
    .article-slideshow.fullscreen .slider .item .picture {
      height: 75vh;
      background-color: transparent; }
      .article-slideshow.fullscreen .slider .item .picture .image-module-image {
        background-size: contain;
        background-repeat: no-repeat; }
      @media only screen and (max-width: 800px) {
        .article-slideshow.fullscreen .slider .item .picture {
          height: 50vh; } }
    @media only screen and (max-width: 800px) {
      .article-slideshow.fullscreen .slider {
        width: 80vw; } }
  .article-slideshow.fullscreen .slick-prev {
    background-image: url("/static/icons/prev_white.svg"); }
  .article-slideshow.fullscreen .slick-next {
    background-image: url("/static/icons/next_white.svg"); }
  .article-slideshow.fullscreen .close {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px; }

.slider * {
  outline: none; }

.slick-prev,
.slick-next {
  background-size: contain;
  background-repeat: no-repeat;
  transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
  .slick-prev:before,
  .slick-next:before {
    display: none; }
  .slick-prev:hover,
  .slick-next:hover {
    opacity: 0.5; }

.slick-prev {
  background-image: url("/static/icons/prev.svg"); }
  @media only screen and (max-width: 800px) {
    .slick-prev {
      transform: translateX(7px) scale(0.75); } }

.slick-next {
  background-image: url("/static/icons/next.svg"); }
  @media only screen and (max-width: 800px) {
    .slick-next {
      transform: translateX(-5px) scale(0.75); } }

.slick-dots {
  display: none !important; }
