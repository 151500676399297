.title-image + .article-headline,
.article-header + .article-headline,
.article-headline:first-child {
  padding: 0 50px;
  margin: 100px auto; }
  @media only screen and (max-width: 800px) {
    .title-image + .article-headline,
    .article-header + .article-headline,
    .article-headline:first-child {
      padding: 0 30px;
      margin: 60px 0; } }

.article-headline {
  padding: 0 50px;
  font-size: 55px;
  line-height: 57px;
  font-weight: 100;
  text-align: center;
  color: #E2263C;
  max-width: 800px;
  margin: 0 auto 50px; }
  .article-headline h1,
  .article-headline h2,
  .article-headline h3 {
    line-height: 1em; }
  .article-headline h1 {
    font-size: 58px; }
  .article-headline h2 {
    font-size: 40px; }
  .article-headline h3 {
    font-size: 32px; }
  @media only screen and (max-width: 800px) {
    .article-headline {
      font-size: 32px;
      padding: 0 30px 30px 30px;
      line-height: 38px; }
      .article-headline h1 {
        font-size: 42px; }
      .article-headline h2 {
        font-size: 28px;
        line-height: 1.25em; }
      .article-headline h3 {
        font-size: 24px; } }
