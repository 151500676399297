.article-video {
  margin: 0 auto;
  max-width: 840px; }
  .article-video.big {
    max-width: initial; }
  .article-video h3 {
    font-size: 30px;
    line-height: 35px;
    font-weight: 100;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase; }
  .article-video .video {
    background-color: #ddd;
    margin: 20px 0;
    height: 475px; }
    .article-video .video.big {
      height: 720px; }
    .article-video .video .thumb {
      height: 100%; }
      .article-video .video .thumb:hover {
        cursor: pointer; }
      .article-video .video .thumb .thumb-inner {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center; }
    .article-video .video iframe {
      height: 100%; }
    @media only screen and (max-width: 800px) {
      .article-video .video {
        height: calc((100vw - 60px) / 16 * 9); }
        .article-video .video.big {
          height: calc((100vw - 60px) / 16 * 9); } }
  .article-video .caption {
    text-align: center; }
    @media only screen and (max-width: 800px) {
      .article-video .caption p {
        font-size: 14px;
        line-height: 18px; } }
