.article-facts {
  margin-bottom: 100px; }

.fact {
  padding: 75px;
  background-color: #E2263C;
  color: white;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .fact h2 {
    font-size: 58px;
    font-weight: 600;
    margin-bottom: 50px;
    text-transform: uppercase; }
    @media only screen and (max-width: 800px) {
      .fact h2 {
        font-size: 38px; } }
  @media only screen and (max-width: 800px) {
    .fact {
      padding: 50px; } }

.fact-list .key {
  font-size: 58px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: uppercase; }
  @media only screen and (max-width: 800px) {
    .fact-list .key {
      font-size: 38px; } }

.fact-list .value {
  font-size: 24px;
  font-weight: 100;
  text-transform: uppercase;
  margin-bottom: 50px;
  line-height: 1.25em; }
  @media only screen and (max-width: 800px) {
    .fact-list .value {
      font-size: 18px; } }

.fact-list:last-child {
  margin-bottom: 0; }
  .fact-list:last-child .value {
    margin-bottom: 0; }
