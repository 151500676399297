.article-suggestions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px; }
  @media only screen and (max-width: 800px) {
    .article-suggestions {
      display: block; } }

.article-suggestions h3 {
  font-size: 30px;
  line-height: 35px;
  font-weight: 100;
  text-align: center;
  text-transform: uppercase;
  grid-area: 1 / 1 / 2 / -1;
  margin-bottom: 50px; }
  @media only screen and (max-width: 800px) {
    .article-suggestions h3 {
      margin-bottom: 30px; } }

.article-suggestions .suggestion {
  background: #666;
  height: 400px;
  color: #fff;
  background-size: cover;
  background-position: center center;
  text-decoration: none;
  cursor: pointer; }
  .article-suggestions .suggestion:nth-child(2) {
    grid-area: 2 / 1 / 3 / 1; }
  .article-suggestions .suggestion:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3; }
  .article-suggestions .suggestion:nth-child(4) {
    grid-area: 2 / 3 / 3 / 4; }
  @media only screen and (max-width: 800px) {
    .article-suggestions .suggestion {
      display: block;
      margin-bottom: 15px;
      height: 300px; }
      .article-suggestions .suggestion:last-child {
        margin-bottom: 0; } }
  .article-suggestions .suggestion .related-image {
    height: 100%; }
  .article-suggestions .suggestion .inner {
    background: rgba(0, 0, 0, 0.4);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px; }
  .article-suggestions .suggestion .index {
    border-radius: 15px;
    height: 30px;
    width: 30px;
    border: 1px solid #fff;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .article-suggestions .suggestion h4 {
    font-size: 30px;
    font-weight: 100;
    letter-spacing: -1px;
    text-align: center;
    margin: 20px 0; }
  .article-suggestions .suggestion .subline {
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    line-height: 1.5em; }

.ie .suggestion {
  height: 300px;
  display: block; }
