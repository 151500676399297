.article-map {
  min-height: 430px;
  display: flex;
  margin-bottom: 100px; }
  @media only screen and (max-width: 800px) {
    .article-map {
      display: block; } }
  .article-map .info {
    background: #E2263C;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
    color: #fff;
    font-weight: 100; }
    @media only screen and (max-width: 800px) {
      .article-map .info {
        padding: 60px 30px; } }
    .article-map .info .big {
      font-size: 30px;
      line-height: 35px; }
    .article-map .info .small {
      margin-top: 35px;
      font-size: 18px;
      line-height: 25px; }
    .article-map .info a {
      color: #fff;
      text-decoration: none;
      border-bottom: 1px solid #fff; }
  .article-map .map {
    background: #666;
    flex: 1;
    overflow: hidden;
    height: 430px; }
    @media only screen and (max-width: 800px) {
      .article-map .map {
        height: 300px; } }
