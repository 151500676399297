.inline-map {
  height: 60vh;
  position: relative;
  display: flex;
  border-top: 1px solid #E2263C; }
  .inline-map .leaflet-div-icon {
    background: transparent;
    border: none; }
  .inline-map .leaflet-marker-icon .number {
    position: relative;
    top: -43px;
    color: #fff;
    font-size: 16px;
    width: 30px;
    text-align: center; }
  @media only screen and (max-width: 800px) {
    .inline-map {
      flex-direction: column-reverse;
      position: relative;
      overflow: hidden;
      height: initial; } }
  .inline-map .list {
    width: 50%;
    max-width: 700px;
    height: 100%; }
    @media only screen and (max-width: 800px) {
      .inline-map .list {
        max-width: initial;
        width: 100%;
        height: 50vh;
        z-index: 10;
        background: white;
        transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
        .inline-map .list.open .open-list svg {
          transform-origin: center;
          transform: rotate(0deg); } }
  .inline-map .list-inner {
    height: 100%;
    position: relative; }
  .inline-map .list-scroll {
    height: 100%;
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
  .inline-map .open-list {
    position: absolute;
    top: -30px;
    background-color: #E2263C;
    left: 0;
    height: 30px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px; }
    .inline-map .open-list svg {
      transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      transform: rotate(180deg); }
  .inline-map .map {
    width: 50%;
    flex: 1; }
    @media only screen and (max-width: 800px) {
      .inline-map .map {
        width: 100%;
        height: 60vh; } }
  .inline-map .poi-entry {
    margin: 10px 10px 0 10px;
    display: flex;
    align-items: center;
    transition: background-color 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    cursor: pointer;
    position: relative; }
    .inline-map .poi-entry .image {
      width: 20vw;
      height: 13vw;
      max-width: 290px;
      max-height: 190px;
      background-size: cover;
      background-position: center;
      position: relative; }
      .inline-map .poi-entry .image .pin {
        position: absolute;
        background-image: url("/static/images/marker_grey.svg");
        width: 30px;
        height: 40px;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        bottom: calc(50% - 20px);
        right: -15px;
        text-align: center;
        color: #fff;
        font-size: 16px;
        padding-top: 8px;
        transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
        z-index: 20; }
    .inline-map .poi-entry .content {
      padding: 0 30px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start; }
      .inline-map .poi-entry .content .title {
        margin-bottom: 10px; }
        .inline-map .poi-entry .content .title h1 {
          font-weight: 100;
          font-size: 23px;
          line-height: 27px;
          color: #E2263C; }
          @media screen and (max-width: 880px) {
            .inline-map .poi-entry .content .title h1 {
              font-size: 16px;
              line-height: 18px;
              margin-top: 10px; } }
      .inline-map .poi-entry .content .summary {
        font-size: 12px;
        line-height: 1.5em;
        margin-bottom: 15px;
        color: rgba(0, 0, 0, 0.8); }
      .inline-map .poi-entry .content .address {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: normal;
        font-size: 12px; }
        @media screen and (max-width: 880px) {
          .inline-map .poi-entry .content .address {
            font-size: 10px; } }
    .inline-map .poi-entry.highlighted {
      background-color: #eeeded; }
      .inline-map .poi-entry.highlighted .pin {
        background-image: url("/static/images/marker_red.svg"); }
    .inline-map .poi-entry:hover {
      background-color: #eeeded; }
    .inline-map .poi-entry:last-child {
      margin-bottom: 10px; }
  .inline-map .link {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 2;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); }
    .inline-map .link svg {
      width: 16px;
      height: 16px; }
      .inline-map .link svg path {
        transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
        fill: #E2263C; }
    .inline-map .link:hover {
      background-color: #E2263C; }
      .inline-map .link:hover svg path {
        fill: white; }
