.article-imageImageText {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px; }
  @media only screen and (max-width: 800px) {
    .article-imageImageText {
      display: block; } }

.article-imageImageText .copy {
  font-size: 18px;
  line-height: 25px; }
  .article-imageImageText .copy.center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; }

.article-imageImageText .caption {
  margin-bottom: 30px; }
