.image-module {
  position: relative;
  overflow: hidden;
  background-color: #ddd; }
  .image-module:hover .image.loaded {
    transition-duration: 10s;
    transform: scale(1.025); }
  .image-module:hover .copyright {
    opacity: 1; }
  .image-module .image-module-image {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 2s cubic-bezier(0.19, 1, 0.22, 1); }
    .image-module .image-module-image.not-loaded {
      transform: scale(1.2) rotate(2deg);
      opacity: 0; }
    .image-module .image-module-image.loaded {
      transform: scale(1) rotate(0deg);
      opacity: 1; }
  .image-module .copyright {
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgba(5, 4, 4, 0.25);
    padding: 5px 10px;
    color: #fff;
    font-size: 10px;
    opacity: .1;
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 40; }
    @media only screen and (max-width: 800px) {
      .image-module .copyright {
        opacity: 1; } }
  .image-module .image-module-inner {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2; }
