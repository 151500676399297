.title-video {
  overflow: hidden;
  margin-bottom: 100px; }
  .title-video.fixed {
    height: 60vh; }
  .title-video.full {
    height: 100vh; }
  .title-video.cinema {
    height: 56.25vw; }
  @media only screen and (max-width: 800px) {
    .title-video {
      height: 50vh;
      margin-bottom: 30px; } }
