.article-fact-slideshow {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px; }
  @media only screen and (max-width: 800px) {
    .article-fact-slideshow {
      display: block; } }

.article-fact-slideshow {
  margin-bottom: 100px; }
  .article-fact-slideshow section {
    max-width: 100%;
    width: 100%;
    padding: 0 10px;
    margin: 0; }
  .article-fact-slideshow > .facts .article-facts {
    margin-bottom: 0; }
    .article-fact-slideshow > .facts .article-facts .fact {
      height: 630px;
      padding: 50px; }
  .article-fact-slideshow .facts {
    grid-area: 1 / 1 / -1 / 2; }
    @media screen and (min-width: 1480px) {
      .article-fact-slideshow .facts {
        width: calc((1280px - 100px) / 3 * 1); } }
    @media screen and (max-width: 1480px) {
      .article-fact-slideshow .facts {
        width: calc((100vw - 200px - 90px) / 3 * 1); } }
    @media screen and (max-width: 1200px) {
      .article-fact-slideshow .facts {
        width: 100%; } }
    .article-fact-slideshow .facts h2 {
      font-size: 42px; }
    .article-fact-slideshow .facts .key {
      font-size: 36px; }
  .article-fact-slideshow .slideshow-section {
    min-height: 100%;
    grid-area: 1 / 2 / -1 / 4;
    position: relative;
    margin-top: -30px; }
    @media screen and (min-width: 1480px) {
      .article-fact-slideshow .slideshow-section {
        width: calc((1280px - 50px) / 3 * 2); } }
    @media screen and (max-width: 1480px) {
      .article-fact-slideshow .slideshow-section {
        width: calc((100vw - 200px - 90px) / 3 * 2); } }
    @media screen and (max-width: 1200px) {
      .article-fact-slideshow .slideshow-section {
        width: 100%; } }
    .article-fact-slideshow .slideshow-section .article-slideshow .slider .item .picture {
      width: 100%;
      margin: 30px 0; }
    .article-fact-slideshow .slideshow-section .slick-prev {
      left: -35px; }
    .article-fact-slideshow .slideshow-section .slick-next {
      right: -35px; }
  .article-fact-slideshow.right .facts {
    grid-area: 1 / 3 / -1 / 4; }
  .article-fact-slideshow.right .slideshow-section {
    grid-area: 1 / 1 / -1 / 3; }
  @media (max-width: 1200px) {
    .article-fact-slideshow {
      display: block; }
      .article-fact-slideshow > .facts .article-facts {
        margin-bottom: 100px; }
        .article-fact-slideshow > .facts .article-facts .fact {
          height: auto; } }
